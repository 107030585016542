.loader-background {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #8d8d8d71;
  display: flex;
  align-items: center; }

/* Footer */
footer {
  border-top: 1px solid #D5D0D0;
  position: relative;
  bottom: 0px;
  min-height: 50px;
  background-color: #FBFBFF;
  font-size: smaller;
  font-family: 'Lato', sans-serif;
  padding-left: 15px;
  padding-right: 15px; }

.footer-link {
  color: #6468c8;
  cursor: pointer; }

.footer-link:hover {
  font-weight: bold;
  text-decoration: none;
  color: #6468c8; }

.footer-row {
  min-height: 50px; }

/* Footer */
.react-pdf__Page__canvas {
  height: 700px !important; }

  @media screen and (max-width: 990px){
    .img-responsive {
      max-width: 90%;
    }
  }

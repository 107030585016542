@media screen and (max-width: 990px){

    html {
        scroll-behavior: smooth !important;
    }

    .attendLessonComponent{
        display: block!important;
    }

    .close {
        opacity: 1 !important;
    }
   
    .col-attendCourseSideMenuStyles {
        top:600px;
        height: 90%;
        overflow-x:auto;
        position: fixed;
        width: 100%;
        transition: 0.5s ease;
        z-index:200
    }

    .class-col-attendCourseSideMenuStyles {
        height: 90%;
        overflow-x:auto;
        top: 10%;
        position: fixed;
        width: 100%;
        transition: 0.5s ease;
    }

    .areaMenu {
        background-color: rgba(0,0,0,0.4);
        position: absolute;
        top: 0;
        width: 100% !important;
        height: 60px;
        z-index: 1200 !important;
    }

    iframe {
        width: 100%;
        max-height: 200px;
    } 

    .menuFixed {
        position: fixed !important;
        z-index: 100;
    }

    .showCourses {
        position: fixed;
        bottom: 80px;
        right: 15px;
        background: #E8E8E8F2;
        border-radius: 15px;
        color: #0C8292;
        border: 0;
        font-weight: 600;
    }
   
    .px-4 {
        padding-left: 0 !important;
    }
    
    .mx-auto {
        padding-left: 16px !important;
    }

    .mt-4 {
        margin-top: 0 !important;
    }
    
    .my-5 {
        padding: 0 !important;
        margin: 0 !important;
    }

    .row {
        .mx-4 {
            padding: 0 !important;
            margin-right: 0 !important;
        }
    }   

    .courseInitial {
        position: fixed;
        bottom: 10px;
        width: 100%;  
    }

    .hold-responsible {
       border-top: solid 1px #ddd;
       padding-top: 35px;
    }

    .hold-workload {
        margin-top: 20px !important;
        margin-bottom: 100px;
    }
    
    .breadcrumb {
        padding: 0px auto !important;
        margin-bottom: 0px !important;
        margin-top: 50px;
    }

    .holdContentInMobile {
        display: inherit;
    }
}